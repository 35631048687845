import React from 'react';
import { Route } from 'react-router-dom';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
    CatalogEntityPage,
    CatalogIndexPage,
    catalogPlugin,
} from '@backstage/plugin-catalog';
import {
    CatalogImportPage,
    catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import { TechRadarPage } from '@backstage/plugin-tech-radar';
import {
    TechDocsIndexPage,
    techdocsPlugin,
    TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';

import { AlertDisplay, OAuthRequestDialog, SignInPage } from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import { EntityValidationPage } from '@backstage/plugin-entity-validation';
import CssBaseline from '@material-ui/core/CssBaseline';
import { UnifiedThemeProvider } from '@backstage/theme';
import LightIcon from '@material-ui/icons/WbSunny';
import DarkIcon from '@material-ui/icons/NightsStay';
import { moonpigTheme } from './components/theme/moonpig'
import { moonpigDarkTheme } from './components/theme/moonpigDark';
import { MoonpigArchitecturePage } from './components/moonpig-architecture-page/MoonpigArchitecturePage';
import { providers } from './IdentityProviders';
import { HomepageCompositionRoot } from '@backstage/plugin-home';
import { HomePageContainer } from './components/home/HomePage';

const app = createApp({
    apis,
    components: {
        SignInPage: props => {
            return <SignInPage {...props} 
                providers={providers} 
                align="center" 
               />
        },
    },
    bindRoutes({ bind }) {
        bind(catalogPlugin.externalRoutes, {
            createComponent: scaffolderPlugin.routes.root,
            viewTechDoc: techdocsPlugin.routes.docRoot,
            createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
        });
        bind(apiDocsPlugin.externalRoutes, {
            registerApi: catalogImportPlugin.routes.importPage,
        });
        bind(scaffolderPlugin.externalRoutes, {
            registerComponent: catalogImportPlugin.routes.importPage,
            viewTechDoc: techdocsPlugin.routes.docRoot,
        });
        bind(orgPlugin.externalRoutes, {
            catalogIndex: catalogPlugin.routes.catalogIndex,
        });
    },
    themes: [
      {
        id: 'moonpig-light',
        title: 'Moonpig Light',
        variant: 'light',
        icon: <LightIcon />,
        Provider: ({ children }) => (
          <UnifiedThemeProvider theme={moonpigTheme} noCssBaseline>
            <CssBaseline />
            {children}
          </UnifiedThemeProvider>
        ),
      },
      {
        id: 'moonpig-dark',
        title: 'Moonpig Dark',
        variant: 'dark',
        icon: <DarkIcon />,
        Provider: ({ children }) => (
          <UnifiedThemeProvider theme={moonpigDarkTheme} noCssBaseline>
            <CssBaseline />
            {children}
          </UnifiedThemeProvider>
        ),
      },
    ],
});

const routes = (
    <FlatRoutes>
        <Route path="/*" element={<HomepageCompositionRoot />}>
            <HomePageContainer />
        </Route>
        <Route path="/catalog" element={<CatalogIndexPage initialKind="System"/>} />
        <Route
            path="/catalog/:namespace/:kind/:name"
            element={<CatalogEntityPage />}
        >
            {entityPage}
        </Route>
        <Route path="/docs" element={<TechDocsIndexPage />} />
        <Route
            path="/docs/:namespace/:kind/:name/*"
            element={<TechDocsReaderPage />}
        >
            <TechDocsAddons>
                <ReportIssue />
            </TechDocsAddons>
        </Route>
        <Route path="/create" element={<ScaffolderPage />} />
        <Route path="/api-docs" element={<ApiExplorerPage />} />
        <Route
            path="/tech-radar"
            element={<TechRadarPage width={1500} height={800} />}
        />
        <Route path="/moonpig-architecture" element={<MoonpigArchitecturePage />} />
        <Route
            path="/catalog-import"
            element={
                <RequirePermission permission={catalogEntityCreatePermission}>
                    <CatalogImportPage />
                </RequirePermission>
            }
        />
        <Route path="/search" element={<SearchPage />}>
            {searchPage}
        </Route>
        <Route path="/settings" element={<UserSettingsPage />} />
        <Route path="/catalog-graph" element={<CatalogGraphPage initialState={{ maxDepth: 1}} />} />
        <Route path="/entity-validation" element={<EntityValidationPage />} />
    </FlatRoutes>
);

export default app.createRoot(
    <>
        <AlertDisplay />
        <OAuthRequestDialog />
        <AppRouter>
            <Root>{routes}</Root>
        </AppRouter>
    </>,
);
